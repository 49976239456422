import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';

const OperatingSystemTagWrapper = styled.div`
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 74px;
  background-color: #e3e7f2;
  padding: 0 11px;
  grid-gap: 0 3px;
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #9da3b7;
  }
  img {
    height: 12px;
    width: 12px;
    position: relative;
    padding-right: 2px;
  }
`;

const OperatingSystemTag = ({
  text,
  iconOnly,
}: {
  text: string;
  iconOnly?: boolean;
}): JSX.Element => {
  const getIcon = () => {
    switch (text.toLowerCase()) {
      case 'windows':
        return '/images/new/windows.svg';
      case 'macos':
        return '/images/new/apple.svg';
      case 'mac':
        return '/images/new/apple.svg';
      default:
        return '';
    }
  };

  return (
    <OperatingSystemTagWrapper>
      <Image alt="" src={getIcon()} width={12} height={12} />
      {!iconOnly && <p>{text}</p>}
    </OperatingSystemTagWrapper>
  );
};

export default OperatingSystemTag;
