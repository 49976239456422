import React from 'react';
import styled from 'styled-components';
import { Box, Column, Row } from '../../Flex';
import Image from 'next/image';

const ItemNumber = styled.p`
  color: #ff003e;
  font-weight: bold;
  background-color: #f6f6f8;
  min-width: 74px;
  min-height: 74px;
  max-height: 74px;
  min-width: 74px;
  border-radius: 50%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemTitle = styled.p`
  color: black;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  margin: 0;
`;

const ItemDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #787f8b;
  max-width: 300px;
  margin: 0;
`;

const ResizableImage = styled(Row)`
  width: 100%;
  > div {
    img {
      width: 100%;
    }
    width: 50%;
  }
  @media only screen and (max-width: 980px) {
    flex-direction: column;
    > div {
      width: 100%;
      margin-left: 0;
    }
  }
`;

const LeftMargin = styled.div`
  margin-left: ${(props: any) => (props.leftMargin ? '4rem' : '0')};
  @media only screen and (max-width: 800px) {
    margin-left: 0;
  }
`;

const ListItemWrapper = styled(Row)`
  grid-gap: 16px;
  @media only screen and (max-width: 400px) {
    flex-direction: column;
    grid-gap: 0;
  }
`;

const ListItem = ({
  number,
  title,
  description,
  imageUrl,
  imageWidth,
  imageHeight,
  negativeMargin,
  leftMargin,
}) => (
  <ResizableImage>
    <ListItemWrapper
      style={{
        alignItems: 'center',
      }}
    >
      <ItemNumber>{number}</ItemNumber>
      <Column
        style={{ height: 150, display: 'flex', justifyContent: 'center' }}
      >
        <ItemTitle>{title}</ItemTitle>
        <ItemDescription>{description}</ItemDescription>
      </Column>
    </ListItemWrapper>
    <Box width={100} height={50} />
    <LeftMargin
      style={{
        marginTop: negativeMargin ? '-3rem' : '0',
      }}
    >
      <Image alt="" width={imageWidth} height={imageHeight} src={imageUrl} />
    </LeftMargin>
  </ResizableImage>
);

export default function BotMartFeatures() {
  return (
    <div id="how-it-works" style={{ margin: '10rem 20px 0 20px' }}>
      <ListItem
        number="1"
        title="Browse bots"
        description="Select from a large variety of our partnered bots."
        imageUrl="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/Group_134347.png"
        imageWidth={400}
        imageHeight={300}
        negativeMargin
        leftMargin={false}
      />
      <Box height={60} />
      <ListItem
        number="2"
        title="Select a listing"
        description="Once you have found the bot, select listing from staff or users."
        imageUrl="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/Group_123239.png"
        imageWidth={412}
        imageHeight={239}
        negativeMargin
        leftMargin={false}
      />
      <Box height={60} />
      <ListItem
        number="3"
        title="Start cooking"
        description="Securely pay and download to access your rental."
        imageUrl="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/Group_223231.png"
        imageWidth={412}
        imageHeight={238}
        negativeMargin
        leftMargin
      />
    </div>
  );
}
