import { motion } from 'framer-motion';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';
import TrendingBotsCarousel from './TrendingBotsCarousel';
import { analytics } from '../../../lib/analytics';

const HeroWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 500px;
  grid-gap: 16px 16px;

  @media only screen and (max-width: 972px) {
    grid-template-columns: 1fr;
    grid-template-rows: 550px 1fr;
  }

  @media only screen and (max-width: 586px) {
    grid-template-rows: minmax(300px, auto) minmax(600px, auto);
  }

  @media only screen and (max-width: 450px) {
    grid-template-rows: minmax(300px, auto) minmax(600px, auto);
  }
`;

const HeroRightBottomBlob = styled.div`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(2, 86, 248, 0.21) 0%,
    rgba(2, 86, 248, 0) 100%
  );
  width: 739px;
  height: 739px;
  bottom: 100px;
  position: absolute;
  @media only screen and (max-width: 586px) {
    display: none;
  }
`;

const HeroRightTopBlob = styled.div`
  position: absolute;
  width: 510px;
  height: 510px;
  top: -120px;
  right: -300px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(28, 186, 240, 0.19) 0%,
    rgba(28, 186, 240, 0) 100%
  );
  @media only screen and (max-width: 586px) {
    display: none;
  }
`;

const HeroWrapperLeft = styled.div`
  margin-top: 60px;
  h1 {
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
    color: #000000;
    max-width: 641px;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-top: 32px;
    color: #8e93a4;
    max-width: 528px;
  }
`;

const HeroWrapperRight = styled.div`
  position: relative;
  height: 600px;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 900px) {
    display: block;
  }
  @media only screen and (max-width: 900px) {
    display: block;
  }
  @media only screen and (max-width: 640px) {
    height: 730px;
    width: 400px;
  }
  @media only screen and (max-width: 500px) {
    width: 380px;
  }
  @media only screen and (max-width: 488px) {
    width: 360px;
  }
  @media only screen and (max-width: 460px) {
    width: 340px;
  }
  @media only screen and (max-width: 440px) {
    width: 320px;
  }
  @media only screen and (max-width: 420px) {
    width: 300px;
  }
  @media only screen and (max-width: 400px) {
    width: 280px;
  }
  @media only screen and (max-width: 380px) {
    width: 260px;
  }
  @media only screen and (max-width: 360px) {
    width: 240px;
  }
  @media only screen and (max-width: 340px) {
    width: 220px;
  }
  @media only screen and (max-width: 320px) {
    width: 240px;
  }
  @media only screen and (max-width: 300px) {
    width: 230px;
  }
  @media only screen and (max-width: 290px) {
    width: 220px;
    height: 750px;
  }
`;

const HeroCTA = styled.button`
  background: #1cbaf0;
  border-radius: 10px;
  max-width: 272px;
  width: 100%;
  height: 52px;
  margin-top: 32px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
`;

const HeroScrollerWidget = styled.div`
  margin-top: 77px;
  .circle {
    background: #e3e7f2;
    border-radius: 100%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  & > p {
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #9da3b7;
  }
`;

const Hero = (props): JSX.Element => {
  const handleScroll = () => {
    analytics.track('User Clicked Upcoming Releases');
    document.getElementById('trendingReleases').scrollIntoView({
      behavior: 'smooth',
    });
  };
  return (
    <HeroWrapper className="h-[100vh]">
      <HeroWrapperLeft>
        <h1>Use your favorite bots without having to buy.</h1>

        <p>
          We've taken the concept of renting a bot and made it easier & more
          secure than ever before.
        </p>

        <Link href="/bots">
          <HeroCTA>Browse Bots</HeroCTA>
        </Link>
        <HeroScrollerWidget>
          <div onClick={handleScroll} className="circle">
            <img alt="" src="/images/new/down-arrow.svg" />
          </div>

          <p>View upcoming releases and more</p>
        </HeroScrollerWidget>
      </HeroWrapperLeft>

      <HeroWrapperRight>
        <HeroRightBottomBlob />
        <HeroRightTopBlob />
        <TrendingBotsCarousel trendingBotsData={props.trendingBots} />
      </HeroWrapperRight>
    </HeroWrapper>
  );
};

export default Hero;
