import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Grid } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { Box, Column, Row } from '../components/Flex';
import { BrowseBotsButton } from '../components/landing/components';
import BotMartFeatures from '../components/landing/sections/BotMartFeatures';
import { Subtitle, Title } from '../components/Text';
import { WHITE_LABEL_LANDING_PAGE_SCROLL_ICON } from '../lib/white-label';
import { COLORS } from '../theme';
import { Media } from '../theme/media';
import { NextSeo } from 'next-seo';

const Container = styled.div`
  overflow: hidden;
`;

const Description = styled(Subtitle)`
  text-align: center;
  max-width: 55rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-top: 2rem;

  @media (max-width: 700px) {
    font-size: 16px;
    line-height: 140%;
  }
`;

const ScrollText = styled.p`
  font-size: 1rem;
  color: ${COLORS.BLACK};
  cursor: pointer;
  user-select: none;
  margin: 0;

  @media (max-width: 380px) {
    font-size: 0.9rem;
  }
`;

const BrowseContainer = styled.div`
  z-index: 3;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  @media only screen and (max-width: 486px) {
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
    div {
      margin-left: 0;
    }
  }
`;

const ScrollIcon = styled.img`
  cursor: pointer;
  margin-right: 14px;
`;

const ScrollContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2rem;

  @media (max-width: 400px) {
    margin-left: 1.25rem;
  }
`;

const BotsImageContainer = styled.div`
  display: flex;
  margin-top: -6rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const HeroTitle = styled(Title)`
  color: ${COLORS.BLACK};
  font-weight: bold;
  text-align: center;
  max-width: 55rem;
  margin: 0;
  font: normal normal bold 50px Inter;
  letter-spacing: 0px;

  @media (max-width: 700px) {
    font-size: 36px;
    line-height: 120%;
  }
`;

const SectionDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #787f8b;
  margin: 0;
`;

const SectionSubdescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  color: #787f8b;
  margin: 0;
  margin-bottom: 1rem;
`;

const ResizableImage = styled(Row)`
  width: 95%;
  margin: auto;
  > div {
    img {
      width: 100%;
    }
    width: 50%;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    > div {
      width: 100%;
      margin-left: 0;
    }
  }
`;

const BotmartFeatureRow = styled.div`
  display: grid;
  grid-template-columns: 46px 1fr;
  grid-gap: 16px;
`;

const BotmartImageWrapper = styled.div`
  @media only screen and (max-width: 750px) {
    width: 100% !important;
    height: fit-content !important;
  }
`;

export async function getStaticProps() {
  return {
    props: {
      defaultSpacing: false,
    },
  };
}

const SafeSectionText = () => (
  <Column>
    <Column style={{ height: 137, width: 137 }}>
      <Image
        alt=""
        height="137"
        width="137"
        src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/Group_43.png"
      />
    </Column>
    <Box height={50} />
    <SectionDescription>
      It’s easy to buy and sell, and fast, oh and secure.
    </SectionDescription>
    <Box height={30} width={30} />
    <BotmartFeatureRow>
      <Column style={{ height: 46, width: 46 }}>
        <Image
          alt=""
          height="46"
          width="46"
          src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/Person.svg"
        />
      </Column>
      <SectionSubdescription>
        Access to a private ticket with your seller and a staff member incase
        any support is needed.
      </SectionSubdescription>
    </BotmartFeatureRow>
    <BotmartFeatureRow>
      <Column style={{ height: 46, width: 46 }}>
        <Image
          alt=""
          height="46"
          width="46"
          src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/PriceTag.svg"
        />
      </Column>
      <SectionSubdescription>
        Recieve instant payouts to your preferred payout source after the
        transaction is complete.
      </SectionSubdescription>
    </BotmartFeatureRow>
  </Column>
);

const SafeSectionImage = () => (
  <BotmartImageWrapper style={{ height: 506, width: 600 }}>
    <Box height={30} />
    <Image
      alt=""
      height="651"
      width="626"
      src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/PiggyBank.svg"
    />
  </BotmartImageWrapper>
);

const metaTitle = 'BotMart';
const metaDescription =
  'The easiest, most affordable place to rent your favorite bots';

export default function Home() {
  return (
    <Container>
      <NextSeo title={metaTitle} description={metaDescription} />
      <Head>
        <title>Home - BotMart</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        ></link>
        <meta property="og:url" content="https://www.botmart.io" />
        <meta name="twitter:site" content="@botmrt" />
        <meta name="twitter:title" content="BotMart" />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <script
          type="text/javascript"
          src="https://www.affiliatly.com/easy_affiliate.js"
        ></script>
      </Head>

      <div
        style={{
          zIndex: 2,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box height={70} />
        <div
          style={{
            margin: '1rem',
          }}
        >
          <HeroTitle>
            The easiest, most affordable place to rent your favorite bots
          </HeroTitle>
          <Description>
            We've taken the concept of renting a bot and made it easier & more
            secure than ever before.
          </Description>
        </div>
        <BrowseContainer>
          <Link href="/bots">
            <BrowseBotsButton>Browse Bots</BrowseBotsButton>
          </Link>
          <AnchorLink
            offset="150"
            href="#how-it-works"
            style={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ScrollContainer>
              <ScrollIcon
                src={WHITE_LABEL_LANDING_PAGE_SCROLL_ICON}
                alt="scroll icon"
              />
              <ScrollText>Scroll to find out more</ScrollText>
            </ScrollContainer>
          </AnchorLink>
        </BrowseContainer>
        <BotsImageContainer>
          {/* <Media lessThan="md">
            <Image
              alt=""
              className="hero-image"
              layout="fixed"
              src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/Group%20123230-min%202.png"
              alt="bots"
              height="800"
              width="1200"
            />
          </Media> */}
          <Media greaterThanOrEqual="md">
            <Image
              className="hero-image"
              layout="fixed"
              src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/Group%20123230-min%202.png"
              alt="bots"
              height="900"
              width="1500"
            />
          </Media>
        </BotsImageContainer>
        <BotMartFeatures />
        <Box height={200} />
        <ResizableImage>
          <Column>
            <Column style={{ height: 137, width: 137 }}>
              <Image
                alt=""
                height="137"
                width="137"
                src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/Group_4221.png"
              />
            </Column>
            <Box height={50} />
            <SectionDescription>
              Whether you're a seller or buyer, BotMart will keep you satisfied.
            </SectionDescription>
            <Box height={30} width={30} />
            <BotmartFeatureRow>
              <Column style={{ height: 46, width: 46 }}>
                <Image
                  alt=""
                  height="46"
                  width="46"
                  src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/Person.svg"
                />
              </Column>
              <SectionSubdescription>
                A large collection of bots to choose from so that you can cook
                the way you want to.
              </SectionSubdescription>
            </BotmartFeatureRow>
            <BotmartFeatureRow>
              <Column style={{ height: 46, width: 46 }}>
                <Image
                  alt=""
                  height="46"
                  width="46"
                  src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/PriceTag.svg"
                />
              </Column>
              <SectionSubdescription>
                Provide your bots to others at an affordable price so you can
                generate passive income.
              </SectionSubdescription>
            </BotmartFeatureRow>
          </Column>
          <Column>
            <Box height={30} />
            <BotmartImageWrapper style={{ height: 651, width: 626 }}>
              <Image
                alt=""
                height="651"
                width="626"
                src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/Group_331.png"
              />
            </BotmartImageWrapper>
          </Column>
        </ResizableImage>
        <Box height={50} />
        <ResizableImage>
          <SafeSectionText />
          <SafeSectionImage />
        </ResizableImage>
      </div>
    </Container>
  );
}
