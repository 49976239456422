import React from 'react';
import styled from 'styled-components';

const SupportedSiteTagWrapper = styled.div`
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 74px;
  background-color: #e3e7f2;
  padding: 0 11px;

  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    color: #000000;
  }
`;

const SupportedSiteTag = ({ text }: { text: string }): JSX.Element => {
  return (
    <SupportedSiteTagWrapper>
      <p>{text}</p>
    </SupportedSiteTagWrapper>
  );
};

export default SupportedSiteTag;
