import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import TrendingBots from './TrendingBots';
import Image from 'next/image';

const TrendingBotsCarouselWrapper = styled.div`
  width: 100%;
  height: 800px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  .carousel-root {
    height: 800px;
  }

  .carousel {
    height: 100%;
    width: 500px;
  }

  @media only screen and (max-width: 900px) {
    justify-content: center;
  }

  @media only screen and (max-width: 630px) {
    display: block;
  }

  @media only screen and (max-width: 576px) {
    height: 750px;
  }

  @media only screen and (max-width: 382px) {
    height: 750px;
  }

  @media only screen and (max-width: 290px) {
    height: 750px;
  }

  .slider-wrapper {
    background: #ffffff;
    border-radius: 14px;
    padding: 22px 0px;
    width: 427px;
    max-width: 100%;
    margin-right: 10px;
    margin-left: 20px;
    box-shadow: 2px 10px 10px rgba(27, 31, 38, 0.15);
    margin-top: 20px;
    @media only screen and (max-width: 900px) {
      box-shadow: 0px 0px 0px rgba(27, 31, 38, 0.15);
      width: 500px;
      max-width: 100%;
      border: 1px solid #e5e8f0;
      margin-right: 0px;
      margin-left: 0px;
    }
    @media only screen and (max-width: 532px) {
      width: 430px;
    }
    @media only screen and (max-width: 460px) {
      width: 390px;
    }

    @media only screen and (max-width: 440px) {
      width: 370px;
    }

    @media only screen and (max-width: 420px) {
      width: 350px;
    }

    @media only screen and (max-width: 400px) {
      width: 340px;
    }

    @media only screen and (max-width: 375px) {
      width: 320px;
    }
    @media only screen and (max-width: 365px) {
      width: 300px;
    }
    @media only screen and (max-width: 325px) {
      width: 290px;
    }
    @media only screen and (max-width: 305px) {
      width: 280px;
    }
  }

  .control-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 200px !important;
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }
`;

const TrendingBotBullet = styled.div<{ isActive: boolean }>`
  height: 9px;
  width: 9px;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 0 4px;
  opacity: ${(props) => (props.isActive ? 1 : 0.3)};
  background-color: ${(props) => (props.isActive ? '#1cbaf0' : '#CCD1E0')};
`;

const TrendingBotNavigationButtonNext = styled.div`
  height: 28px;
  width: 28px;
  position: absolute;
  right: 120px;
  bottom: 200px !important;
  cursor: pointer;
  z-index: 1;

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const TrendingBotNavigationButtonPrev = styled.div`
  height: 28px;
  width: 28px;
  position: absolute;
  left: 120px;
  bottom: 200px !important;
  cursor: pointer;
  z-index: 1;
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const TrendingBotsCarousel = ({ trendingBotsData }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const body = useMemo(() => {
    return trendingBotsData.map((data, index) => {
      return (
        <div key={index}>
          <TrendingBots
            botId={data.botId}
            botImage={data.imageUrl}
            botName={data.name}
            tags={data.tags?.split(',') || []}
            os={data.operatingSystem?.split(',') || []}
            listingsCount={data.listings}
            salesCount={data.sales}
            avgPrice={data.avgPrice}
            listingMode={data.listingMode}
          />
        </div>
      );
    });
  }, [trendingBotsData]);

  return (
    <TrendingBotsCarouselWrapper>
      <Carousel
        onChange={(e) => {
          setActiveIndex(e);
        }}
        renderIndicator={(
          clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
          isSelected: boolean,
          index: number,
          label: string,
        ) => {
          return (
            <div key={index}>
              <TrendingBotBullet onClick={clickHandler} isActive={isSelected} />
            </div>
          );
        }}
        renderArrowPrev={(onClickHandler: () => void, hasPrev: boolean) => {
          return (
            <TrendingBotNavigationButtonPrev onClick={onClickHandler}>
              <Image
                alt=""
                src={
                  hasPrev
                    ? '/images/new/hero/prev-arrow.svg'
                    : '/images/new/hero/prev-arrow-disabled.svg'
                }
                height={28}
                width={28}
              />
            </TrendingBotNavigationButtonPrev>
          );
        }}
        renderArrowNext={(onClickHandler: () => void, hasNext: boolean) => {
          return (
            <TrendingBotNavigationButtonNext onClick={onClickHandler}>
              <Image
                alt=""
                src={
                  hasNext
                    ? '/images/new/hero/next-arrow.svg'
                    : '/images/new/hero/next-arrow-disabled.svg'
                }
                height={28}
                width={28}
              />
            </TrendingBotNavigationButtonNext>
          );
        }}
        stopOnHover
        interval={7000}
        swipeable={true}
        showThumbs={false}
        showIndicators={true}
        showStatus={false}
        showArrows={true}
        emulateTouch
        infiniteLoop
        autoPlay={true}
      >
        {body}
      </Carousel>
    </TrendingBotsCarouselWrapper>
  );
};

export default TrendingBotsCarousel;
