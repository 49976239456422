import styled from 'styled-components';
import { BackgroundButton } from '../Form';

const BrowseBotsButton = styled(BackgroundButton)`
  @media (max-width: 380px) {
    font-size: 0.9rem;
  }
`;

const Background = styled.div`
  height: 1500px;
  width: 100%;
  position: absolute;
  background: transparent
    radial-gradient(
      closest-side at 52% 61%,
      #bdc5f4 0%,
      #bdc5f406 91%,
      #bdc5f400 100%
    )
    0% 0% no-repeat padding-box;
  opacity: 0.71;
  top: -25rem;
  pointer-events: none;
`;

const Image = styled.img`
  width: 1.5rem;
  position: absolute;
  top: 0.75rem;
  right: 1rem;
`;

const Dots = () => <Image alt="" src="images/dots@2x.png" />;

export { BrowseBotsButton, Dots, Background };
