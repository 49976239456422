import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import Link from 'next/link';
import { analytics } from '../../../lib/analytics';

const TrendingReleaseCardWrapper = styled.div`
  background: #ffffff;
  box-shadow: 2px 5px 10px rgba(27, 31, 38, 0.15);
  border-radius: 14px;
  @media only screen and (max-width: 576px) {
    box-shadow: none;
    border: 1px solid #e5e8f0;
  }
  height: inherit;
`;

const TrendingReleaseCardImageWrapper = styled.div`
  height: 250px;
  width: 100%;
  padding: 20px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border-bottom: 1px solid #e5e8f0;
  @media only screen and (max-width: 500px) {
    padding: 8px;
  }
  img {
    height: 100% !important;
    width: 100% !important;
    position: relative !important;
    object-fit: contain;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
`;

const TrendingReleaseCardContentWrapper = styled.div`
  padding: 20px 25px;
  padding-bottom: 26px;
  @media only screen and (max-width: 500px) {
    padding: 8px;
  }
  .date-container {
    background: #1cbaf0;
    border-radius: 74px;
    height: 26px;
    padding: 0 11px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: #ffffff;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-top: 11px;
    height: 44px;
    overflow: hidden;
  }
`;

const TrendingReleaseCardRow = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  @media only screen and (max-width: 340px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
  margin-top: 15px;
`;

const TrendingReleaseInfoContainer = styled.div<{
  backgroundColor: string;
  color: string;
}>`
  background: ${(props) => props.backgroundColor};
  border-radius: 10px;
  padding: 7px 14px;
  .price {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
  }
  .type {
    color: ${(props) => props.color};
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
  }
`;

const TrendingReleaseCardButton = styled.button`
  background: #1cbaf0;
  border-radius: 10px;
  height: 52px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  margin-top: 15px;
`;

interface TrendingReleaseCardProps {
  image: string;
  title: string;
  date: string;
  retailPrice: number;
  resellPrice: number;
}

const TrendingReleaseCard = (props: TrendingReleaseCardProps): JSX.Element => {
  return (
    <TrendingReleaseCardWrapper>
      <TrendingReleaseCardImageWrapper>
        <Image
          alt={`${props.title} Image`}
          src={props.image}
          width={700}
          height={500}
        />
      </TrendingReleaseCardImageWrapper>
      <TrendingReleaseCardContentWrapper>
        <div>
          <div className="date-container">{props.date}</div>
          <div className="title">{props.title}</div>
        </div>
        <div>
          <TrendingReleaseCardRow>
            <TrendingReleaseInfoContainer
              backgroundColor="rgba(28, 186, 240, 0.24)"
              color="#1CBAF0"
            >
              <p className="price">${props.retailPrice}</p>
              <p className="type">Retail</p>
            </TrendingReleaseInfoContainer>
            <TrendingReleaseInfoContainer
              backgroundColor="rgba(35, 229, 136, 0.24)"
              color="#23E588"
            >
              <p className="price">${props.resellPrice}</p>
              <p className="type">Resell</p>
            </TrendingReleaseInfoContainer>
          </TrendingReleaseCardRow>
          <Link href="/bots">
            <TrendingReleaseCardButton
              onClick={() => {
                analytics.track('User Clicked Release', {
                  ...props,
                });
              }}
            >
              View Bots
            </TrendingReleaseCardButton>
          </Link>
        </div>
      </TrendingReleaseCardContentWrapper>
    </TrendingReleaseCardWrapper>
  );
};

export default TrendingReleaseCard;
