import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import SupportedSiteTag from '../../bots/common/SupportedSiteTag';
import OperatingSystemTag from '../../bots/common/OperatingSystemTag';
import { Box } from '../../Flex';
import router from 'next/router';
import { analytics } from '../../../lib/analytics';
import { BadgeCheck } from 'lucide-react';
import {
  WHITE_LABEL_ACCENT_COLOR,
  WHITE_LABEL_BRAND,
} from '../../../lib/white-label';
import { Tooltip } from 'react-tooltip';

const TrendingBotsCardWrapper = styled.div`
  max-width: 100%;
  padding: 0 27px;
  @media only screen and (max-width: 400px) {
    padding: 0 8px;
  }
  height: fit-content;
  & > h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #05030d;
    text-align: left !important;
  }
`;

const TrendingBotsHead = styled.div`
  display: grid;
  grid-template-columns: 114px 1fr;
  grid-gap: 0 19px;
  margin-top: 28px;
  width: 100%;
  border-bottom: 1px solid #e5e8f0;
  padding-bottom: 25px;
  @media only screen and (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-gap: 10px 0;
    div > div > img {
      width: 100% !important;
    }
  }
`;

const TrendingBotseHeadImage = styled.div`
  position: relative;
  height: 114px;
  width: 114px;
  span {
    border-radius: 10px !important;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const TrendingBotsHeadBody = styled.div`
  & > .row {
    display: flex;
    grid-gap: 7px;
    flex-wrap: wrap;
  }
  & > .trending-head-row {
    max-height: 61px;
    overflow-y: auto;
  }
  .product-name {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #05030d;
    margin-bottom: 6px;
  }
  .supported-retailers {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #9da3b7;
    margin-bottom: 4px;
    text-align: left !important;
  }
  .supported-retailers-list {
    max-height: 59px;
    overflow-y: auto;
  }
`;

const TrendingBotsInfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 12px;
  margin-bottom: 11px;
  @media only screen and (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-gap: 12px 0;
  }
`;

const TrendingBotsInfoContainer = styled.div<{
  backgroundColor: string;
  color: string;
}>`
  display: grid;
  grid-template-columns: 57px 1fr;
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor};
  .icon-wrapper {
    height: 100%;
    width: 60px;
    background-color: ${(props) => props.color};
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
      height: 28px !important;
      width: 28px !important;
      position: relative !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }
  .body-wrapper {
    padding: 9px 13px;
    .count {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: #000000;
      text-align: left !important;
    }
    .type {
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: ${(props) => props.color};
      text-align: left !important;
    }
  }
`;

const TrendingBotFooter = styled.div`
  padding-top: 21px;
  border-top: 1px solid #e5e8f0;
  margin-top: 21px;
`;

const TrendingBotButton = styled.button`
  background: #1cbaf0;
  border-radius: 10px;
  width: 100%;
  height: 52px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
`;

interface TrendingBotsProps {
  tags: string[];
  os: string[];
  botId: string;
  botName: string;
  botImage: string;
  listingsCount: number;
  salesCount: number;
  avgPrice: number;
  listingMode: string;
}

const TrendingBots = (props: TrendingBotsProps): JSX.Element => {
  return (
    <TrendingBotsCardWrapper>
      <h1>Trending Bots</h1>
      <TrendingBotsHead>
        <TrendingBotseHeadImage>
          <Image
            alt=""
            layout="fill"
            src={props.botImage}
            className="rounded"
          />
        </TrendingBotseHeadImage>
        <TrendingBotsHeadBody>
          <div className="row trending-head-row">
            <div className="flex flex-row">
              <p className="text-lg text-left font-bold">{props.botName}</p>
              {props.listingMode === 'DIRECT' && (
                <>
                  <BadgeCheck
                    color={'#FFFFFF'}
                    fill={WHITE_LABEL_ACCENT_COLOR}
                    className="ml-2"
                    data-tooltip-content={`Partnered with ${WHITE_LABEL_BRAND}`}
                    data-tooltip-id={`${props.botName}-partner-tooltip`}
                  />
                  <Tooltip
                    id={`${props.botName}-partner-tooltip`}
                    place="right"
                  />
                </>
              )}
            </div>
            <div className="flex flex-row gap-2">
              {props?.os?.map((o, index) => (
                <OperatingSystemTag key={index} text={o} />
              ))}
            </div>
          </div>

          <p className="text-sm text-left text-gray-400 py-1">
            Supported retailers
          </p>
          <div className="row supported-retailers-list">
            {props?.tags?.map((tag, index) => (
              <SupportedSiteTag key={index} text={tag} />
            ))}
          </div>
        </TrendingBotsHeadBody>
      </TrendingBotsHead>
      <Box height={24} />
      <TrendingBotsInfoRow>
        <TrendingBotsInfoContainer
          backgroundColor="rgba(28, 186, 240, 0.24)"
          color="#1CBAF0"
        >
          <div className="icon-wrapper">
            <Image alt="" src="/images/new/hero/key.svg" layout="fill" />
          </div>
          <div className="body-wrapper">
            <p className="count">{props.listingsCount}</p>
            <p className="type">Listings</p>
          </div>
        </TrendingBotsInfoContainer>
        <TrendingBotsInfoContainer
          backgroundColor="rgba(35, 229, 136, 0.24)"
          color="#23E588"
        >
          <div className="icon-wrapper">
            <Image alt="" src="/images/new/hero/sales.svg" layout="fill" />
          </div>
          <div className="body-wrapper">
            <p className="count">{props.salesCount}</p>
            <p className="type">Sales</p>
          </div>
        </TrendingBotsInfoContainer>
      </TrendingBotsInfoRow>

      <TrendingBotsInfoContainer
        backgroundColor="rgba(2, 86, 248, 0.24)"
        color="#0256F8"
      >
        <div className="icon-wrapper">
          <Image alt="" src="/images/new/hero/listing.svg" layout="fill" />
        </div>
        <div className="body-wrapper">
          <p className="count">${(props.avgPrice / 100).toFixed(2)}/day</p>
          <p className="type">Average listing price</p>
        </div>
      </TrendingBotsInfoContainer>
      <TrendingBotFooter>
        <TrendingBotButton
          onClick={() => {
            analytics.track('User Clicked Trending Bot', {
              botId: props.botId,
              avgPrice: props.avgPrice,
            });
            router.push(`/bots/${props.botId}`);
          }}
        >
          Rent {props.botName}
        </TrendingBotButton>
      </TrendingBotFooter>
    </TrendingBotsCardWrapper>
  );
};

export default TrendingBots;
