import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import styled from 'styled-components';
import { Box, Row } from '../components/Flex';
import { BrowseBotsButton } from '../components/landing/components';
import { Subtitle, Title } from '../components/Text';
import {
  WHITE_LABEL_LANDING_PAGE_SCROLL_ICON,
  WHITE_LABEL_MARKET,
  WHITE_LABEL_TWITTER,
  WHITE_LABEL_URL,
} from '../lib/white-label';
import { COLORS } from '../theme';
import { Media } from '../theme/media';
import { NextSeo } from 'next-seo';

const Container = styled.div`
  overflow-x: hidden;
  @media only screen and (max-width: 750px) {
    #anchor-end,
    #anchor_end_2 {
      height: fit-content !important;
      padding: 24px 0;
    }
    #anchor_end_2 {
      margin-top: 80px;
    }
  }
`;

const Description = styled(Subtitle)`
  text-align: center;
  max-width: 38rem;

  @media (max-width: 700px) {
    font-size: 16px;
    line-height: 140%;
  }
`;

const ScrollText = styled.p`
  font-size: 1rem;
  color: ${COLORS.BLACK};
  cursor: pointer;
  user-select: none;
  margin: 0;

  @media (max-width: 380px) {
    font-size: 0.9rem;
  }
`;

const BrowseContainer = styled.div`
  z-index: 3;
  margin-top: -2rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  @media only screen and (max-width: 486px) {
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
    div {
      margin-left: 0;
    }
  }
`;

const ScrollIcon = styled.img`
  cursor: pointer;
  margin-right: 14px;
`;

const ScrollContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2rem;

  @media (max-width: 400px) {
    margin-left: 1.25rem;
  }
`;

const BotsImageContainer = styled.div`
  margin-top: -2.75rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const HeroTitle = styled(Title)`
  color: ${COLORS.BLACK};
  font-weight: bold;
  text-align: center;
  max-width: 38rem;
  margin: 0;
  font: normal normal bold 48px Poppins;
  letter-spacing: 0px;

  @media (max-width: 700px) {
    font-size: 36px;
    line-height: 120%;
  }
`;

const ResizableImageRow = styled(Row)`
  width: 95%;
  margin: auto;
  > div {
    img {
      width: 100%;
    }
    width: 50%;
  }
  @media only screen and (max-width: 980px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90% !important;
    > div {
      align-items: center;
      justify-content: center;
      width: 100% !important;
      margin-left: 0;
      p {
        width: 100% !important;
        text-align: center !important;
      }
    }
  }
`;

const CopsupplyImageWrapper = styled.div`
  @media only screen and (max-width: 980px) {
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 750px) {
    width: 100% !important;
    height: fit-content !important;
    span {
      width: 100% !important;
    }
  }
`;

export async function getStaticProps() {
  return {
    props: {
      defaultSpacing: false,
    },
  };
}

const metaTitle = 'CopSupply - Sneaker bot rentals';
const metaDescription =
  'Use your favorite bots without having to buy at CopSupply.';

export default function Home() {
  return (
    <Container>
      <NextSeo title={metaTitle} description={metaDescription} />
      <Head>
        <title>Home - {WHITE_LABEL_MARKET}</title>
        <meta property="og:url" content={WHITE_LABEL_URL} />
        <meta name="twitter:site" content={WHITE_LABEL_TWITTER} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <script
          type="text/javascript"
          src="https://www.affiliatly.com/easy_affiliate.js"
        ></script>
      </Head>

      <div
        style={{
          zIndex: 2,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box height={70} />
        <div
          style={{
            margin: '1rem',
          }}
        >
          <Description>
            CopSupply offers access and support for some of the most successful
            bots around.
          </Description>
        </div>
        <Box height={70} />

        <BrowseContainer>
          <Link href="/bots">
            <BrowseBotsButton>Browse Bots</BrowseBotsButton>
          </Link>
          <AnchorLink
            offset="150"
            href="#anchor-end"
            style={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ScrollContainer>
              <ScrollIcon
                src={WHITE_LABEL_LANDING_PAGE_SCROLL_ICON}
                alt="scroll icon"
              />
              <ScrollText>Scroll to find out more</ScrollText>
            </ScrollContainer>
          </AnchorLink>
        </BrowseContainer>
        <BotsImageContainer>
          {/* <Media lessThan="md">
            <Image
 alt=""
              className="hero-image"
              layout="fixed"
              src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/copsupply/Group%203.svg"
              alt="bots"
              height="624"
              width="1151"
            />
          </Media> */}
          <Media greaterThanOrEqual="md">
            <Image
              className="hero-image"
              layout="fixed"
              src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/copsupply/Group%203.svg"
              alt="bots"
              height="694"
              width="1279"
            />
          </Media>
        </BotsImageContainer>
        <Box height={100} />
        <div
          id="anchor-end"
          style={{
            backgroundColor: '#F6F7FB',
            height: '625',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ResizableImageRow style={{ width: '80%', maxWidth: 1000 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Description
                style={{
                  textAlign: 'left',
                  width: 400,
                }}
              >
                Search for bots by what stores they support and the operating
                system. Bots are almost always in stock and ready to go.
              </Description>
              <Box height={30} width={30} />
            </div>
            <CopsupplyImageWrapper style={{ height: 339, width: 462 }}>
              <Image
                alt=""
                height="339"
                width="462"
                layout="fixed"
                src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/copsupply/Group%2015.svg"
              />
            </CopsupplyImageWrapper>
          </ResizableImageRow>
        </div>
        <div
          id="anchor-end"
          style={{
            backgroundColor: 'white',
            height: 625,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ResizableImageRow style={{ width: '80%', maxWidth: 1000 }}>
            <CopsupplyImageWrapper style={{ height: 400, width: 400 }}>
              <Image
                alt=""
                height="400"
                width="400"
                layout="fixed"
                src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/copsupply/Group%2025.svg"
              />
            </CopsupplyImageWrapper>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Description
                style={{
                  textAlign: 'left',
                  width: 300,
                }}
              >
                Install the bot on your computer, get famliar with how it works
                and then it’s time to cook!
              </Description>
            </div>
          </ResizableImageRow>
        </div>
        <div
          id="anchor_end_2"
          style={{
            backgroundColor: '#F6F7FB',
            height: 625,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ResizableImageRow style={{ width: '80%', maxWidth: 1000 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Description
                style={{
                  textAlign: 'left',
                  width: 400,
                }}
              >
                Get detailed instructions plus 1-on-1 support through our
                Discord ticket system. We help the whole way.
              </Description>
            </div>
            <CopsupplyImageWrapper style={{ height: 339, width: 462 }}>
              <Image
                alt=""
                height="339"
                width="462"
                layout="fixed"
                src="https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/copsupply/Group%2022.svg"
              />
            </CopsupplyImageWrapper>
          </ResizableImageRow>
        </div>
      </div>
    </Container>
  );
}
