import React, { ChangeEvent, FocusEventHandler } from 'react';
import styled from 'styled-components';
import { COLORS } from '../theme/index';
import { Search } from 'lucide-react';

const InputContainer = styled.div`
  height: 56px;
  background: #f6f9fc 0% 0% no-repeat padding-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  padding-left: 1.225rem;
  padding-right: 2.225rem;
  max-width: 600px;
`;

const Input = styled.input`
  font: normal normal 500 1rem / normal Poppins;
  letter-spacing: 0.4px;
  opacity: 1;
  background-color: transparent;
  border: none;
  outline: none !important;
  width: 100%;
  box-sizing: border-box;
  color: #a1a3ac;
  -webkit-text-fill-color: #a1a3ac;
  opacity: ${(props) => (props.disabled ? 0.8 : 1)};

  &:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  ::placeholder {
    color: #a1a3ac;
  }
`;

const Icon = styled.img`
  width: 1rem;
  right: 1.4rem;
`;

type EditableInputProps = {
  icon?: any;
  placeholder?: string;
  value?: string;
  onChangeText?: (text: string) => void;
  onIconClick?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  type?: string;
  disabled?: boolean;
  ref?: React.MutableRefObject<any>;
  name?: string;
  // formik fields
  field?: any;
  form?: any;
  meta?: any;
};

export const EditableInput = ({
  onChangeText,
  onChange,
  onBlur,
  onFocus,
  value,
  placeholder,
  icon,
  type,
  ref,
  name,
  onIconClick,
  disabled,
  field,
  meta,
  form,
}: EditableInputProps) => {
  if (onChangeText) {
    onChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChangeText(e.target.value);
    };
  }

  let error;
  if (form && field) {
    error = form.touched[field.name] ? form.errors[field.name] : null;
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <InputContainer>
          <Input
            ref={ref}
            type={type || 'text'}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled || false}
            name={name}
            style={{ cursor: disabled ? 'not-allowed' : 'text' }}
            {...(field || {})}
          />
        </InputContainer>
        {icon && (
          <span
            onClick={onIconClick}
            className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-100 border-gray-200"
          >
            <Icon src={icon} onClick={onIconClick} alt="" />
          </span>
        )}
      </div>
      {error && (
        <>
          <p className="mt-2 text-md text-red-400 font-semibold">
            <span className="font-medium">{error}</span>.
          </p>
        </>
      )}
    </div>
  );
};

export const TextInput = (props) => (
  <InputContainer>
    <Input
      type={props.type || 'text'}
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.onChange}
      disabled={props.disabled || false}
    />
  </InputContainer>
);

type Props = {
  value?: string; // allows uncontrolled input, helps with debounced setStateValue
  placeholderOverride?: string;
  onChangeText(text: string): void;
};

export const SearchBar = (props: Props) => {
  const onChange = (e) => {
    props.onChangeText(e.target.value);
  };
  return (
    <div className="relative flex-1 md:grow-0 mb-4">
      <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
      <Input
        onChange={onChange}
        type="search"
        placeholder="Search..."
        className="w-full rounded-lg bg-gray-100 pl-8 lg:w-[336px]"
      />
    </div>
  );
};

export const TextButton = styled.button`
  display: inline-block;
  color: ${COLORS.GRAY};
  text-decoration: none;
  cursor: pointer;
  padding: 8px 20px;
  font: normal normal 600 16px/25px Poppins;
  transition: opacity 250ms;
  &:hover {
    opacity: ${(props: BackgroundButtonProps) =>
      !props.disabled ? '0.7' : 'none'};
  }
  border: none;
  background: none;
`;

type BackgroundButtonProps = {
  disabled?: boolean;
};

export const BackgroundButton = styled(TextButton)`
  color: white;
  background:
    ${
      process.env.WHITE_LABEL_ORG === 'botmart'
        ? '#FF003E;'
        : `transparent linear-gradient(198deg, ${COLORS.ACCENT} 0%, #0d9de5 100%) no-repeat padding-box;`
    }
  opacity: ${(props: BackgroundButtonProps) => (props.disabled ? '.5' : '1')};
  cursor: ${(props: BackgroundButtonProps) =>
    props.disabled ? 'not-allowed' : 'pointer'};
  box-shadow: 10px 10px 20px #0d173a1a;
  border-radius: 5px;
  margin-left: 0.25rem;
  transition: ${(props: BackgroundButtonProps) =>
    !props.disabled ? 'opacity 250ms, transform 250ms' : 'none'};

  &:active {
    transform: ${(props: BackgroundButtonProps) =>
      !props.disabled ? 'scale(0.9)' : 'none'};
  }
`;

export const GreenButton = styled.button`
  width: 160px;
  height: 40px;
  background: linear-gradient(180deg, #18ef97, #1ee593);
  border-radius: 4px;
  border: 0;
  outline: 0;
  font: normal normal medium 13px/20px Poppins;
  color: #ffffff;
  text-align: center;
  transition: opacity 300ms;
  cursor: ${(props: BackgroundButtonProps) =>
    props.disabled ? 'auto' : 'pointer'};
  opacity: ${(props: BackgroundButtonProps) => (props.disabled ? '0.4' : '1')};
`;
