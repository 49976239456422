import styled from 'styled-components';
import { COLORS } from '../theme/index';

export const Title = styled.p`
  color: ${COLORS.BLACK};
  font-weight: bold;
  text-align: center;
  max-width: 38rem;
  margin: 0;
  font: normal normal bold 48px/59px Poppins;
  letter-spacing: 0px;
`;

export const Subtitle = styled.p`
  font: normal normal normal 18px/25px Poppins;
  color: ${COLORS.GRAY};
`;

export const HeadingOne = ({
  children,
  className,
}: {
  children: any;
  className?: any;
}) => (
  <p
    className={`${
      className ?? ''
    } text-2xl md:text-3xl font-bold pt-0 md:pt-5 pb-2`}
  >
    {children}
  </p>
);

export const HeadingTwo = styled.p`
  font: normal normal bold 25px/38px Poppins;
  color: ${COLORS.BLACK};
  margin: 0;
`;

export const Subheading = styled.p`
  font: normal normal normal 18px/25px Poppins;
  color: ${COLORS.GRAY};
  max-width: 23.125rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const Description = styled.p`
  font: normal normal normal 12px/18x Poppins;
  color: ${COLORS.GRAY};
`;

export const Accent = styled.p`
  font: normal normal medium 17px/26px Poppins;
  color: ${COLORS.ACCENT};
`;

export const BotSummaryBody = styled.div`
  font: normal normal normal 18px/25px Poppins;
  color: ${COLORS.GRAY};

  h2 {
    font: normal normal bold 18px/25px Poppins;
    color: ${COLORS.GRAY};
    max-width: 23.125rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  p {
    img {
      margin: 1rem auto;
      max-width: 100%;
      height: auto;
    }
  }

  hr {
    margin: 1rem;
  }
`;

export const Body = styled.div`
  font: normal normal normal 18px/25px Poppins;
  color: ${COLORS.GRAY};
`;

export const LineRow = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const Line = styled.div`
  height: 2.25rem;
  width: 0.125rem;
  background-color: ${COLORS.ACCENT};
`;

export const LineTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 0.5rem;
  justify-content: center;
`;

type TextProps = {
  titleFontSize?: number | string;
  subtitleFontSize?: number | string;
};

export const LineTitle = styled.p`
  text-align: left;
  font: normal normal normal 18px / 13px Poppins;
  letter-spacing: 0px;
  color: ${COLORS.GRAY};
  margin: auto 0;

  @media (max-width: 440px) {
    font-size: 1rem;
  }
`;

export const LineSubtitle = styled.p`
  text-align: left;
  font: normal normal normal 13px / 13px Poppins;
  letter-spacing: 0px;
  color: ${COLORS.GRAY};
  margin: auto 0;

  @media (max-width: 440px) {
    font-size: 0.8rem;
  }
`;

interface TextWithLineProps extends TextProps {
  title: string;
  subtitle: string;
}

export const TextWithLine = (props: TextWithLineProps) => (
  <LineRow>
    <Line />
    <LineTextContainer>
      <LineTitle>{props.title}</LineTitle>
      <LineSubtitle>{props.subtitle}</LineSubtitle>
    </LineTextContainer>
  </LineRow>
);
